var GoogleMapsLoader = require('google-maps');
GoogleMapsLoader.KEY = 'AIzaSyAwiUzFkeicpr0pK5kcC4jX1FtJIg4F1qQ';



(function () {
    var GoogleMapsLoader = require('google-maps');
    GoogleMapsLoader.KEY = "AIzaSyAzB_AGsF2SGPVGQFOQ7gZOwCwclTrkIoI";

    GoogleMapsLoader.load(function (google) {

        var map;

        function initialize() {
            var glat = 51.334818;
            glong = 3.208129;

            var mapOptions = {
                zoom: 16,
                scrollwheel: false,
                center: new google.maps.LatLng(glat, glong)
            };
            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                map: map
                //icon: '/bundles/sitefrontend/img/marker.png'
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();


/*
(function() {



    gmaps.load(function(google) {

        var map;
        var directionsService = new google.maps.DirectionsService;
        var directionsDisplay = new google.maps.DirectionsRenderer;



        function initialize() {
            var glat = 51.3344763;
            glong = 3.2076684;


            map = new google.maps.Map(document.getElementById('maps'), {
                zoom: 12,
                center: {
                    lat: glat,
                    lng: glong
                }
            });
            directionsDisplay.setMap(map);

            calculateAndDisplayRoute(directionsService, directionsDisplay);

            function calculateAndDisplayRoute(directionsService, directionsDisplay) {
                directionsService.route({
                    origin: '51.335517, 3.210965',
                    destination: '51.334446, 3.208784',
                    travelMode: 'WALKING'
                }, function(response, status) {
                    if (status === 'OK') {
                        directionsDisplay.setDirections(response);
                        var distance = response.routes[0].legs[0].distance[0];

                        var infowindow2 = new google.maps.InfoWindow();
                        infowindow2.setContent("<span class='infowindow2'><p><strong>" +response.routes[0].legs[0].steps[0].duration.text  +
                                              " walking </strong></p></span>");
                        infowindow2.setPosition(response.routes[0].legs[0].steps[0].path[5]);
                        infowindow2.open(map);
                    } else {
                        window.alert('Directions request failed due to ' + status);
                    }
                });
            }


        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();*/