var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyAwiUzFkeicpr0pK5kcC4jX1FtJIg4F1qQ';
var routePoints;
var cards;
var infowindow = null;
var gmarkers = [];



(function () {

    $('.partners').on('click', 'a', function(e) {
        e.target.target = '_blank';
    });

    var $brugge;
    var $zeebrugge;


    function getLanguage() {
        $lang = $('html').attr('lang');

        switch ($lang) {
            case 'nl':
                $brugge = 'Brugge';
                $zeebrugge = 'Zeebrugge';

                break;
            case 'fr':
                $brugge = 'Bruges';
                $zeebrugge = 'Zeebrugge';

                break;
            case 'de':
                $brugge = 'Brügge';
                $zeebrugge = 'Zeebrugge';

                break;
            case 'es':
                $brugge = 'Brujas';
                $zeebrugge = 'Zeebrugge';

                break;
            default:
                $brugge = 'Bruges';
                $zeebrugge = 'Zeebruges';

        }
    }


    gmaps.load(function (google) {

        var map, infoWindow;
        var glat = 51.208737;
        glong = 3.224383;

        var brugge = {
            lat: glat,
            lng: glong
        };
        var zeebrugge = {
            lat: 51.331256,
            lng: 3.205963
        };

        var icons = {
            giftshop: {
                icon: '/bundles/sitefrontend/img/pins/giftshop_nr.png',
                size: new google.maps.Size(20,32),
                color: '#53d40d'

            },
            drinks: {
                icon: '/bundles/sitefrontend/img/pins/drinken_nr.png',
                size: new google.maps.Size(20,32),
                color: '#13c1f0'

            },
            food: {
                icon: '/bundles/sitefrontend/img/pins/eten_nr.png',
                size: new google.maps.Size(20,32),
                color: '#000'

            },
            clothes: {
                icon: '/bundles/sitefrontend/img/pins/shopping_nr.png',
                size: new google.maps.Size(20,32),
                color: '#e6296b'

            },
            activity: {
                icon: '/bundles/sitefrontend/img/pins/activiteiten_nr.png',
                size: new google.maps.Size(20,32),
                color: '#ffa800'


            },
        };

        getLanguage();



        function CenterControl(controlDiv, map) {

            // Set CSS for the control border.
            var controlBrugesUI = document.createElement('div');
            controlBrugesUI.style.cursor = 'pointer';
            controlBrugesUI.style.textAlign = 'center';
            controlBrugesUI.classList.add('active-map');
            controlDiv.appendChild(controlBrugesUI);

            // Set CSS for the control interior.
            var controlBrugesText = document.createElement('div');
            controlBrugesText.classList.add('map-switch');
            controlBrugesText.innerHTML = $brugge;
            controlBrugesUI.appendChild(controlBrugesText);


            var controlZeebrugesUI = document.createElement('div');
            controlDiv.appendChild(controlZeebrugesUI);


            // Set CSS for the control interior.
            var controlZeebrugesText = document.createElement('div');
            controlZeebrugesText.classList.add('map-switch');
            controlZeebrugesText.innerHTML = $zeebrugge;
            controlZeebrugesUI.appendChild(controlZeebrugesText);

            // Setup the click event listeners: simply set the map to Chicago.
            controlBrugesUI.addEventListener('click', function () {
                map.setCenter(brugge);
                $('.active-map').removeClass('active-map');
                $(this).addClass('active-map');
            });
            controlZeebrugesUI.addEventListener('click', function () {
                map.setCenter(zeebrugge);
                $('.active-map').removeClass('active-map');
                $(this).addClass('active-map');
            });
        }


        function initialize() {
            var mapOptions = {
                zoom: 14,
                styles: [
                    {
                      featureType: 'poi',
                      stylers: [{visibility: 'off'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text',
                      stylers: [{visibility: 'off'}]
                    },
                    {
                      featureType: 'administrative',
                      elementType: 'geometry',
                      stylers: [{visibility: 'off'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'labels.icon',
                      stylers: [{visibility: 'off'}]
                    }
                  ],
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_CENTER
                },
                center: {
                    lat: glat,
                    lng: glong
                }
            };



            var polygons = [];
            var lineSymbol = {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                strokeColor: '#c6235c',
                scale: 4
            };


            var arr = [];
            var map = new google.maps.Map(document.getElementById('loyaltymap'),
                mapOptions);

                var myLatLng = {lat:  51.196383, lng: 3.226894};
                var bus = {
                    url: 'https://cruise-express.be/bundles/sitefrontend/img/busicon.png',

                  };

                var cemarker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon: bus
                  });


            $.ajax({
                type: 'GET',
                url: Routing.generate('site_frontend_routes_fetch_cards_ajax_call'),
                success: function (data) {
                    allCards = data.cards;
                    getCards(allCards);
                }
            });



            $('.select-routes').on('change', function () {
                var $option = $(this).find('option:selected');
                var routeId = $option.val();
                $.ajax({
                    type: 'GET',
                    url: Routing.generate('site_frontend_routes_fetch_points_ajax_call'),
                    data: {
                        routeId: routeId,
                    },
                    success: function (data) {
                        if (data.status) {
                            routePoints = data.points;
                            drawRoute(routePoints);

                        }
                    }
                });

            });





            var cards = [];

            function getCards(element) {
                var infoWindow = new google.maps.InfoWindow();
                for (var i = 0; i < element.length; i++) {
                    var sites = element[i];
                    var card = {
                        position: new google.maps.LatLng(element[i].lat, element[i].lng),
                        type: element[i].filter,
                        listnumber: element[i].listnumber,
                        title: element[i].title,
                        subtitle: element[i].subTitle,
                        discount: element[i].discount,
                        intro: element[i].intro,
                        body: element[i].body
                    };


                    cards.push(card);

                }

                google.maps.event.addListener(map, "click", function () {
                    infoWindow.close();
                });

                var ftype;



                cards.forEach(function (card) {
                    var image = {
                        url: icons[card.type].icon,
                        origin: new google.maps.Point(0, 0),
                        // The anchor for this image is the base of the flagpole at (0, 32).
                        labelOrigin : new google.maps.Point( 21 , 38)

                    };

                    var marker = new google.maps.Marker({
                        position: card.position,
                        icon: image,
                        label: {
                            text: card.listnumber,
                            fontWeight: 'bold',
                            color: icons[card.type].color
                        },
                        map: map,
                        title: card.title,
                        type: card.type
                    });



                    var doesThisCardHaveASubTitleOrNahh = card.subtitle ? '<h2>' + card.subtitle  + '</h2>' : '';

                    var doesThisCardHaveANumberOrNahh = card.listnumber ? '<h2>' + card.listnumber  + '</h2>' : '';

                    var content =
                        '<div class="partner partner-map">  <div class="partner-title "> </div>' +
                        '<h1>' + card.title + '</h1>' +
                        doesThisCardHaveASubTitleOrNahh +
                        '<div class="partner-korting korting-map korting-type-'+card.type+' " > <img class="voordeel-img" src="/bundles/sitefrontend/img/svg/voordeel-'+card.type+'.svg" alt="">' +
                        card.discount +
                        '</div>' +
                        '<div class="partner-beschrijving-map"><div class="partner-location"><span class="partner-img partner-mob"></span>' +
                        card.intro + '</div>' +
                        ' <div class="partner-hours"><span class="partner-img partner-mob"></span>' +
                        card.body + '</div></div></div>';

                    (function (marker, card) {
                        google.maps.event.addListener(marker, "click", function (e) {
                            //Wrap the content inside an HTML DIV in order to set height and width of InfoWindow.
                            infoWindow.setContent(content);
                            infoWindow.open(map, marker);
                        });
                    })(marker, card);

                    $('.filterselect').on('change', function () {
                        filter = $(this).attr('id');
                        if ($(this).is(':checked')) {
                            for (i = 0; i < cards.length; i++) {
                                if (marker.type == filter) {
                                    marker.setVisible(true);
                                }
                            }

                        } else {
                            for (i = 0; i < cards.length; i++) {
                                if (marker.type == filter) {
                                    marker.setVisible(false);
                                }
                            }
                        }

                    });

                });

            }



            var points = [];
            // Define the LatLng coordinates for the polygon's path.
            function drawRoute(element) {


                for (var z = 0; z < routePoints.length; z++) {

                    var ll = {
                        lat: parseFloat(routePoints[z].lat),
                        lng: parseFloat(routePoints[z].lng)
                    };

                    points.push(ll);

                }

                polygons = new google.maps.Polyline({
                    path: points,
                    strokeOpacity: 0,
                    geodesic: true,
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '20px'
                    }],
                });
                polygons.setMap(map);
                points = [];

                $('.select-routes').on('change', function () {
                    polygons.setMap(null);
                });

            }

            var centerControlDiv = document.createElement('div');
            centerControlDiv.classList.add('map-controls');
            var centerControl = new CenterControl(centerControlDiv, map);

            centerControlDiv.index = 1;
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);



        }



        google.maps.event.addDomListener(window, 'load', initialize);


    });



})();
