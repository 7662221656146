require("./plugins/bootstrap.js");
require("./plugins/magnific-popup.js");
require("./plugins/slick.js");
require("./plugins/jpages.min.js");
require("./plugins/bootstrap-datepicker.js");

require("./modules/slider.js");
require("./modules/forms.js");
require("./modules/reviews.js");
require("./modules/fastbooker.js");
require("./modules/mobile-nav.js");


if ($('#maps').length) {
    require("./modules/location.js");
}

$(".newbook").each(function( index ) {
  var currentlink = $( this ).attr("href");
  var newlink = decoratelinker(currentlink);
  $(this).attr("href", newlink);
});


if ($('#loyaltymap').length) {
  require("./modules/loyaltymap.js");
}

/* when document is ready */
$(function(){
  /* initiate the plugin */
  $("div.holder").jPages({
      containerID  : "itemContainer",
      perPage      : 6,
      previous     : "",
      next         : ""
  });


  $('.js-showpartners').click(function(){
    $("div.holder").jPages("destroy");
    $(this).remove();
  });
});

/* when document is ready */
$(function(){
  /* initiate the plugin */
  $("div.holder2").jPages({
      containerID  : "itemContainer2",
      perPage      : 4,
      previous     : "",
      next         : ""
  });


});


$('.js-ship').on('click', function(e) {
    $(this).next('.ship-timetable').toggleClass('docked');
    $(this).toggleClass('clicked');
});

$('.js-mobile-menu').on('click', function(event) {
  event.preventDefault();
  $(this).toggleClass('isToggled');
  $('.js-main-nav').toggleClass('isOpen');

  $('body').toggleClass('fixed');
});

$('.gallery__images').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled : true
  }
});

$('.newbook').magnificPopup({
  type: 'iframe'
});

var $btn = $('.js-scroll');
	if($btn.length){
	//var $hh = $('.main__slider').outerHeight() + $('.main__slider').offset().top + 100;
  var $hh = $('.header__top').outerHeight();
		$btn.on('click', function(e){
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $('.section--pagelinks').offset().top - $hh
			}, 700);
		});
  }

$(".js-deelnemen").click(function() {
    $('html, body').animate({
        scrollTop: $("#partners").offset().top
    }, 1400);
});


$(".js-voordelen").click(function() {
  $('html, body').animate({
      scrollTop: $("#voordelen").offset().top
  }, 1400);
});


var $showReview = $('.js-post-review');
var $closeReview = $('.js-close-review');
var $reviewBox = $('.js-review');

$showReview.on('click', function(event) {
  event.preventDefault();
  $reviewBox.css('visibility', 'visible');
  $reviewBox.css('opacity', '1');
  $('body').css('overflow', 'hidden');
});

$closeReview.on('click', function(event) {
  event.preventDefault();
  $reviewBox.css('visibility', 'hidden');
  $reviewBox.css('opacity', '0');
  $('body').css('overflow', 'auto');

});

$(".service__open").on('click', function(event){
  $(this).closest(".service").toggleClass("opened");
});


$(".btn--header").on('click', function(e){
  e.stopPropagation();
  var scrollto = $(this).attr('href');
  if (scrollto.indexOf("#") >= 0){
    $([document.documentElement, document.body]).animate({
      scrollTop: $(scrollto).offset().top
    }, 500);
    return false;
  }else{
    return true;
  }
});


function decoratelinker(destinationUrl) {
  var trackers, linker;
  //decorate the destinationUrl
  //if universal analytics is used
  if(ga !== undefined && typeof ga.getAll === 'function') {
      trackers = ga.getAll();
      if(trackers.length) {
      linker = new window.gaplugins.Linker(trackers[0]);
      destinationUrl = linker.decorate(destinationUrl);
      } 
  }
  return(destinationUrl);
  }


